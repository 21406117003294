/* eslint-disable */
// todo: fix eslint errors
import { FC, memo, useEffect, useRef } from 'react';
import { AnimatedMessageProps } from './AnimatedMessage.interface';
// animations
import { onHTMLElementEnterOnWindow } from 'src/animations/scroll';

import * as style from './AnimatedMessage.module.scss';

function createAnimation(
  canvas: HTMLCanvasElement,
  message: string,
  targetRef: Element
) {
  const COLOR = 'white';
  const MESSAGE = message;

  const FONT_SIZE = 450;
  const AMOUNT = 2000;
  const CLEAR_AMOUNT = 2;
  const SIZE = 1;
  const INITIAL_DISPLACEMENT = 100;
  const INITIAL_VELOCITY = 5;
  const VELOCITY_RETENTION = 0.95;
  const SETTLE_SPEED = 0.01;
  const FLEE_SPEED = 0.1;
  const FLEE_DISTANCE = 50;
  const FLEE = true;
  const SCATTER_VELOCITY = 30;
  const SCATTER = true;

  if (
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    )
  ) {
    // Mobile
    return;
  }

  // MAIN
  const CLEAR_RADIUS = Math.round(CLEAR_AMOUNT + SIZE);
  const MOVED_O = [...Array(AMOUNT).fill(null)];

  const ctx = canvas.getContext('2d');

  if (ctx === null) {
    return;
  }

  let POINTS: any[] = [];
  let MOVED: any[] = [];
  let movedLength = 0;

  const MOUSE = {
    x: 0,
    y: 0,
  };

  function Point(x: any, y: any, r: any, g: any, b: any, a: any) {
    const num = 6.28;
    const angle = Math.random() * num;

    let lastX = 0;
    let lastY = 0;

    this.x =
      canvas.width / 2 - x + (Math.random() - 0.5) * INITIAL_DISPLACEMENT;
    this.y =
      canvas.height / 2 - y + (Math.random() - 0.5) * INITIAL_DISPLACEMENT;
    this.velx = INITIAL_VELOCITY * Math.cos(angle);
    this.vely = INITIAL_VELOCITY * Math.sin(angle);
    this.targetX = canvas.width / 2 - x;
    this.targetY = canvas.height / 2 - y;
    this.r = r;
    this.g = g;
    this.b = b;
    this.a = a;
    this.moved = false;
    MOVED[movedLength] = this;
    movedLength++;

    const fleeFrom = () => {
      this.velx -= (MOUSE.x - this.x) * FLEE_SPEED;
      this.vely -= (MOUSE.y - this.y) * FLEE_SPEED;
    };

    const settleTo = () => {
      this.velx =
        VELOCITY_RETENTION *
        (this.velx + (this.targetX - this.x) * SETTLE_SPEED);
      this.vely =
        VELOCITY_RETENTION *
        (this.vely + (this.targetY - this.y) * SETTLE_SPEED);
    };

    this.scatter = function () {
      const unitVecTo = (x: number, y: number) => {
        const dx = x - this.x;
        const dy = y - this.y;
        const d = Math.sqrt(dx * dx + dy * dy);

        return {
          x: dx / d,
          y: dy / d,
        };
      };

      const unit = unitVecTo(MOUSE.x, MOUSE.y);
      const vel = SCATTER_VELOCITY * (0.5 + Math.random() / 2);

      this.velx = -unit.x * vel;
      this.vely = -unit.y * vel;
    };

    const checkMove = () => {
      this.moved =
        !Math.abs(Math.round(this.targetX - this.x)) == 0 ||
        !Math.abs(Math.round(this.targetY - this.y)) == 0 ||
        !Math.abs(Math.round(this.velx)) == 0 ||
        !Math.abs(Math.round(this.vely)) == 0;
    };

    this.simpleMove = function () {
      checkMove();
      if (!this.moved) {
        return;
      }

      lastX = this.x;
      lastY = this.y;
      this.x += this.velx;
      this.y += this.vely;
      MOVED[movedLength] = this;
      movedLength++;
    };

    this.move = function () {
      if (this.distanceTo(MOUSE.x, MOUSE.y) <= FLEE_DISTANCE) {
        fleeFrom();
      } else {
        settleTo();
      }

      this.simpleMove();
    };

    this.distanceTo = function (x, y) {
      const dx = x - this.x;
      const dy = y - this.y;

      return Math.sqrt(dx * dx + dy * dy);
    };

    this.clear = function () {
      ctx.clearRect(
        lastX - CLEAR_RADIUS,
        lastY - CLEAR_RADIUS,
        CLEAR_RADIUS * 2,
        CLEAR_RADIUS * 2
      );
    };

    this.draw = () => {
      ctx.fillStyle = `rgba(${this.r},${this.g},${this.b},${this.a})`;
      ctx.beginPath();
      ctx.arc(this.x, this.y, SIZE, 0, 2 * Math.PI);
      ctx.fill();
    };
  }

  window.addEventListener('resize', () => {
    resizeCanvas();
    adjustText();
  });

  if (FLEE) {
    canvas.addEventListener('mousemove', (event) => {
      MOUSE.x = event.clientX - canvas.getBoundingClientRect().left;
      MOUSE.y = event.clientY - canvas.getBoundingClientRect().top;
    });
  }

  if (SCATTER) {
    canvas.addEventListener('click', (event) => {
      MOUSE.x = event.clientX - canvas.getBoundingClientRect().left;
      MOUSE.y = event.clientY - canvas.getBoundingClientRect().top;
      for (let i = 0; i < POINTS.length; i++) {
        POINTS[i].scatter();
      }
    });
  }

  function resizeCanvas() {
    canvas.width = canvas.clientWidth;
    canvas.height = canvas.clientHeight;
  }

  const adjustText = () => {
    ctx.fillStyle = COLOR;
    ctx.textBaseline = 'middle';
    ctx.textAlign = 'center';
    ctx.font = `${FONT_SIZE}px Arial`;
    ctx.fillText(MESSAGE, canvas.width / 2, canvas.height / 2);
    const textWidth = ctx.measureText(MESSAGE).width;

    if (textWidth === 0) {
      return;
    }

    const minX = canvas.width / 2 - textWidth / 2;
    const minY = canvas.height / 2 - FONT_SIZE / 2;
    const data = ctx.getImageData(minX, minY, textWidth, FONT_SIZE).data;

    ctx.clearRect(0, 0, canvas.width, canvas.height);
    let isBlank = true;

    for (let i = 0; i < data.length; i++) {
      if (data[i] !== 0) {
        isBlank = false;
        break;
      }
    }

    if (!isBlank) {
      let count = 0;
      let curr = 0;
      let num = 0;
      let x = 0;
      let y = 0;
      const w = Math.floor(textWidth);

      POINTS = [];
      while (count < AMOUNT) {
        while (curr == 0) {
          num = Math.floor(Math.random() * data.length);
          curr = data[num];
        }

        num = Math.floor(num / 4);
        x = w / 2 - (num % w);
        y = FONT_SIZE / 2 - Math.floor(num / w);
        POINTS.push(
          new Point(
            x,
            y,
            data[num * 4],
            data[num * 4 + 1],
            data[num * 4 + 2],
            data[num * 4 + 3]
          )
        );
        curr = 0;
        count++;
      }
    }
  };

  function init() {
    resizeCanvas();
    adjustText();
    window.requestAnimationFrame(animate);
  }

  function animate() {
    update();
    draw();
  }

  function update() {
    for (let i = 0; i < POINTS.length; i++) {
      POINTS[i].move();
    }
  }

  function draw() {
    for (let i = 0; i < movedLength; i++) {
      MOVED[i].clear();
    }

    MOVED = MOVED_O;
    movedLength = 0;

    for (let i = 0; i < POINTS.length; i++) {
      POINTS[i].draw();
    }

    onHTMLElementEnterOnWindow(targetRef, () => {
      window.requestAnimationFrame(animate);
    });
  }

  init();
}

const AnimatedMessage: FC<AnimatedMessageProps> = ({ message, targetRef }) => {
  const ref = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    if (targetRef && targetRef.current && ref.current) {
      createAnimation(ref.current, message, targetRef.current);
    }
  }, []);

  return <canvas ref={ref} className={style.canvas} />;
};

export default memo(AnimatedMessage);
