import { FC, memo, useEffect, useState, useRef } from 'react';
import { Link } from 'gatsby';
import classNames from 'classnames';
// components
import Typography from 'src/components/Typography';
import Button from 'src/components/Button';
import Section from 'src/components/Section';
import AnimatedMessage from 'src/components/AnimatedMessage';
import Icon from 'src/components/Icon';
// utils
import { getBrowserName } from 'src/services/helpers';

// styles
import * as styles from './PageNotFound.module.scss';

const PageNotFound: FC = () => {
  const [isAnimated, setIsAnimated] = useState(true);
  // refs
  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const browser = getBrowserName();

    if (browser === 'Apple Safari') {
      setIsAnimated(false);
    }
  }, []);

  return (
    <Section className={styles.wrapper} ref={wrapperRef}>
      <div
        className={classNames(styles.art, { [styles.animatedArt]: isAnimated })}
      >
        {isAnimated ? (
          <AnimatedMessage message='404' targetRef={wrapperRef} />
        ) : (
          <Icon name='pageNotFound' />
        )}
      </div>
      {isAnimated && <Icon name='pageNotFound' className={styles.phoneIcon} />}
      <Typography
        className={classNames(styles.text, {
          [styles.animatedText]: isAnimated,
        })}
        id='text'
        variant='h2'
      >
        Page not found
      </Typography>
      <Link to='/'>
        <Button
          className={styles.button}
          variant='secondary'
          type='button'
          text='Back home'
          iconRightName='arrowRight'
        />
      </Link>
    </Section>
  );
};

export default memo(PageNotFound);
